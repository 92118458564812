import { useState, useEffect, useMemo, useCallback } from "react";
import axios from "axios";
import { cities } from "../helpers/config";
import {
  scheduleKey,
  addressKey,
  addressIdKey,
  streetsUrl,
  numbersUrl,
  scheduleURL,
  buildCode,
  FORCE_CLEAN_DATE_STRING,
  LAST_CLEAN_TIMESTAMP_KEY_STRING,
  SCHEDULE_EXPIRATION_MS,
} from "../helpers/config";

type ItemIdValue = {
  id: string | number;
  value: string;
};
type Street = ItemIdValue;
type Address = ItemIdValue;
type WasteType = "MIXED" | "PAPER" | "PLASTIC" | "GLASS" | "TREE" | "BIG" | "BIO";
type WasteCollectionEntry = {
  date: string; // Format: YYYY-MM-DD
  types: WasteType[];
};
type WasteCollectionSchedule = WasteCollectionEntry[];
type SavedSchedule = {
  data: WasteCollectionSchedule;
  timestamp?: number;
};

// TODO add this to API
function removeSpacesFromDates(input: SavedSchedule): SavedSchedule {
  return {
    data: input.data
      .map((entry: WasteCollectionEntry) => ({
        ...entry,
        date: entry.date
          .replaceAll(/\s+/g, "") // Remove all spaces
          .replace(/-(\d)$/, "-0$1"), // Ensure single-digit day is zero-padded
      }))
      .sort((a: any, b: any) => new Date(a.date).getTime() - new Date(b.date).getTime()), // Sort dates in ascending order
  };
}

function isScheduleValid(savedSchedule: SavedSchedule | null, currentAddressId: string | null): boolean {
  if (!savedSchedule || !savedSchedule.timestamp) return false;
  const { timestamp } = savedSchedule;
  const storedAddressId = localStorage.getItem(addressIdKey);

  // Data wymuszonego czyszczenia
  const FORCE_CLEAN_DATE = new Date(FORCE_CLEAN_DATE_STRING).getTime();
  // Klucz w localStorage do zapisu daty ostatniego czyszczenia
  const LAST_CLEAN_TIMESTAMP_KEY = LAST_CLEAN_TIMESTAMP_KEY_STRING;
  // Odczyt daty ostatniego czyszczenia
  const lastCleanTimestamp = parseInt(localStorage.getItem(LAST_CLEAN_TIMESTAMP_KEY) || "0", 10);

  // Jeśli obecna data jest późniejsza niż data czyszczenia, ale zapisany timestamp jest starszy niż ta data
  if (Date.now() >= FORCE_CLEAN_DATE && lastCleanTimestamp < FORCE_CLEAN_DATE) {
    localStorage.setItem(LAST_CLEAN_TIMESTAMP_KEY, Date.now().toString()); // Zapisz datę czyszczenia
    return false;
  }

  return (
    storedAddressId === currentAddressId &&
    Date.now() - timestamp < SCHEDULE_EXPIRATION_MS
  );
}

export function useGetSchedule() {
  const [cityId, setCityId] = useState<string | null>(null);
  const [streets, setStreets] = useState<Street[] | null>(null);
  const [streetId, setStreetId] = useState<string | null>(null);
  const [streetName, setStreetName] = useState<string | null>(null);
  const [addresses, setAddresses] = useState<Address[] | null>(null);
  const [addressId, setAddressId] = useState<string | null>(
    () => localStorage.getItem(addressIdKey)?.split(',')[0] || null
  );
  const [addressLabel, setAddressLabel] = useState<string | null>(null);
  const [fullAddress, setFullAddress] = useState<string | null>(
    () => localStorage.getItem(addressKey) || null
  );
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [error, setError] = useState<boolean>(false);
  const [schedule, setSchedule] = useState<any>(
    () => {
      const savedSchedule: SavedSchedule | null = JSON.parse(localStorage.getItem(scheduleKey) || "null");
      if (savedSchedule && isScheduleValid(savedSchedule, localStorage.getItem(addressIdKey))) {
        return removeSpacesFromDates(savedSchedule).data;
      }
      return [];
    }
  );

  const formattedCities = useMemo(
    () =>
      cities.map((item: ItemIdValue) => ({
        value: item.id,
        label: item.value,
      })),
    []
  );

  const formattedStreets = useMemo(
    () =>
      streets?.map((item: Street) => ({
        value: item.id,
        label: item.value,
      })) || null,
    [streets]
  );

  const formattedNumbers = useMemo(
    () =>
      addresses?.map((item: Address) => ({
        value: item.id,
        label: item.value,
      })) || null,
    [addresses]
  );

  const fetchStreets = useCallback(async () => {
    if (!cityId) return;
    try {
      const { data } = await axios.get(`${streetsUrl}/${cityId}`);
      setStreets(data);
    } catch (err: any) {
      setError(true);
      throw new Error(`Failed to fetch streets for city ID: ${cityId} - ${err.message}, ${buildCode}`);
    }
  }, [cityId]);

  const fetchAddresses = useCallback(async () => {
    if (!cityId || !streetId) return;
    try {
      const { data } = await axios.get(`${numbersUrl}/${cityId}/${streetId}`);
      setAddresses(data);
    } catch (err: any) {
      setError(true);
      throw new Error(`Failed to fetch addresses for city ID: ${cityId}, street ID: ${streetId} - ${err.message}, ${buildCode}`);
    }
  }, [cityId, streetId]);

  const fetchSchedule = useCallback(async () => {
    if (!addressId) return;

    const savedSchedule: SavedSchedule | null = JSON.parse(localStorage.getItem(scheduleKey) || "null");

    if (savedSchedule && isScheduleValid(savedSchedule, addressId)) {
        setSchedule(removeSpacesFromDates(savedSchedule).data);
        return;
    }

    const MAX_RETRIES = 3;
    let attempt = 0;

    setIsLoading(true);
    try {
        while (attempt < MAX_RETRIES) {
            try {
                const { data } = await axios.get(scheduleURL, { params: { id: addressId } });
                // Przetwarzanie danych po udanym żądaniu
                const formattedData = removeSpacesFromDates(data).data || [];
                setSchedule(formattedData);

                const scheduleToSave: SavedSchedule = {
                    data: formattedData,
                    timestamp: Date.now(),
                };

                localStorage.setItem(scheduleKey, JSON.stringify(scheduleToSave));
                localStorage.setItem(addressIdKey, addressId);

                if (streetName && addressLabel) {
                    const fullAddr = `ul. ${streetName} ${addressLabel}`;
                    localStorage.setItem(addressKey, fullAddr);
                    setFullAddress(fullAddr);
                }

                setError(false);
                return; // Zakończ pętlę po sukcesie
            } catch (err: any) {
                if (err.message === "Network Error") {
                    attempt += 1;
                    if (attempt >= MAX_RETRIES) {
                        setError(true);
                        break; // Zakończ pętlę, ale nie rzucaj błędu
                    }
                } else {
                  throw new Error(`Failed to fetch streets for city ID: ${cityId} - ${err.message}, ${buildCode}`);
                }
            }
        }
    } catch (err: any) {
        setError(true);
        throw new Error(`Failed to fetch streets for city ID: ${cityId} - ${err.message}, ${buildCode}`);
    } finally {
        resetModalState();
        setIsLoading(false);
    }
    // eslint-disable-next-line
}, [addressId, streetName, addressLabel]);

  useEffect(() => {
    fetchStreets();
  }, [fetchStreets]);

  useEffect(() => {
    fetchAddresses();
  }, [fetchAddresses]);

  useEffect(() => {
    fetchSchedule();
  }, [fetchSchedule, addressId, streetName, addressLabel]);

  const resetState = useCallback(() => {
    setCityId(null);
    setStreetId(null);
    setAddressId(null);
    setAddresses(null);
    setSchedule([]);
    localStorage.removeItem(scheduleKey);
    localStorage.removeItem(addressKey);
    localStorage.removeItem(addressIdKey);
  }, []);

  const resetModalState = useCallback(() => {
    setCityId(null);
    setStreetId(null);
    setAddressId(null);
    setAddresses(null);
  }, []);

  return {
    isLoading,
    fullAddress,
    schedule,
    error,
    cityId,
    formattedCities,
    formattedStreets,
    addresses,
    formattedNumbers,
    setAddressId,
    setAddressLabel,
    setCityId,
    setStreetId,
    setStreetName,
    setAddresses,
    resetState,
    resetModalState,
  };
}
