import React from "react";

type BannerAdProps = {
    url: string;
    imageUrl: string;
    title: string;
    description: string;
    price: string;
    oldPrice: string;
    percent: string;
};

export const BannerAd: React.FC<BannerAdProps> = ({
    url,
    imageUrl,
    title,
    description,
    price,
    oldPrice,
    percent,
}) => {
    return <div>
        <a href={url} style={{textDecoration: "none"}}>
        <p style={{fontSize: "0.6rem", color: "gray"}}>REKLAMA</p>
        <div style={{background: "white", padding: "0.5rem", display: "flex", alignItems: "center", border: "1px solid #ddd", margin: "auto"}}>
            <div style={{marginRight: "15px"}}>
            <img 
                src={imageUrl} 
                alt="Zdjęcie produktu" 
                style={{maxWidth: "100px", maxHeight: "100px", objectFit: "cover", borderRadius: "5px"}}
            />
            </div>
            <div style={{display: "flex", flexDirection: "column", justifyContent: "center"}}>
            <h2 style={{fontSize: "1rem", margin: "0 0 4px 0", lineHeight: "1.1rem"}}>
                {title}
            </h2>
            <p style={{fontSize: "0.6rem", margin: "0 0 5px 0", color: "#666"}}>
                {description}
            </p>
            <p style={{fontSize: "1.2rem", fontWeight: "bold", color: "#e91e63", margin: 0}}>
                {price}
                <span style={{color: "gray", fontSize: "0.75rem"}}>
                <s>{oldPrice}</s>
                </span><br />
                <span style={{padding: "0.2rem", backgroundColor: "rgb(204, 12, 57)", color: "white", fontSize: "0.75rem"}}>
                Oszczędzasz {percent}
                </span>
            </p>
            </div>
        </div>
        </a>
    </div>;
};

export const bannerData = [
    {
        "url": "https://amzn.to/4aLj9yy",
        "imageUrl": "https://m.media-amazon.com/images/I/618-7FMf52L._SX522_.jpg",
        "title": "SONGMICS kosz na śmieci kuchenne, 3 x 8 l",
        "description": "kosz na śmieci, potrójny stalowy kosz na śmieci, dla małych rodzin, pedały i wiaderka, łatwy do czyszczenia, srebrny i czarny LTB24L",
        "price": "240,54 PLN",
        "oldPrice": "282,99",
        "percent": "15%"
    },
    {
        "url": "https://amzn.to/4jIulAj",
        "imageUrl": "https://m.media-amazon.com/images/I/71PSZ8OyIqL._AC_SX679_.jpg",
        "title": "KADAX Potrójny uchwyt na worki na śmieci,",
        "description": "stojak do segregacji śmieci z pokrywką z tworzywa sztucznego, uchwyt na worki na śmieci o pojemności 120 l (zielono-żółto-niebieski)",
        "price": "79,47 PLN",
        "oldPrice": "99,90",
        "percent": "20%"
    },
    {
        "url": "https://amzn.to/3EolJPf",
        "imageUrl": "https://m.media-amazon.com/images/I/81en5u81F+L._AC_SX679_.jpg",
        "title": "VOUNOT Kompostownik ogrodowy, 300 l",
        "description": "kosz na kompost na odpady ogrodowe, z polipropylenu, odporny na wstrząsy i promieniowanie UV, czarno-zielony",
        "price": "182,59 PLN",
        "oldPrice": "249,90",
        "percent": "26%"
    },
    {
        "url": "https://amzn.to/4hjCMAm",
        "imageUrl": "https://m.media-amazon.com/images/I/71PScc7gftL._AC_SX679_.jpg",
        "title": "Froster Zgniatacz Puszek",
        "description": "Solidna, Wisząca Zgniatarka do Puszek, maks. 500 ml, z Otwieraczem do Butelek",
        "price": "69 PLN",
        "oldPrice": "99",
        "percent": "30%"
    },
    {
        "url": "https://amzn.to/4hoLxcx",
        "imageUrl": "https://m.media-amazon.com/images/I/71cy5mOLK0L._SX522_.jpg",
        "title": "KADAX System segregacji śmieci",
        "description": "modułowy kosz na śmieci z tworzywa sztucznego, kosz z recyklingu do kuchni i łazienki, pojemnik na odpady organiczne, kosz do sortowania śmieci (3 x 25 l)",
        "price": "109,47 PLN",
        "oldPrice": "139",
        "percent": "21%"
    }
];
