import { Heading, Text } from "@chakra-ui/react";
import Select from "react-select";

import { useSchedule } from "../context/ScheduleProvider";
import { lightTheme } from "../theme";

export const SelectAddressForm = () => {
  const {
    cityId,
    formattedCities,
    formattedStreets,
    addresses,
    formattedNumbers,
    setAddressId,
    setAddressLabel,
    setCityId,
    setStreetId,
    setStreetName,
    setAddresses,
    isLoading,
  } = useSchedule();

  return (
    isLoading ? (<>Wczytywanie harmonogramu...</>) : (<>
      <Heading color={lightTheme.text} size="sm">
        Wybierz rejon wywozu
      </Heading>
      <Text mt="1rem" fontSize="sm">
        Wybierz mejscowość
      </Text>
      <Select
        placeholder="Miejscowość"
        options={formattedCities}
        onChange={(e: any) => {
          setCityId(e.value);
          setStreetId(null);
          setStreetName(null);
          setAddresses(null);
        }}
      />
      {cityId && (
        <>
          <Text mt="1rem" fontSize="sm">
            Wybierz ulicę
          </Text>
          <Select
            placeholder="Ulica"
            options={formattedStreets ?? []}
            onChange={(e: any) => {
              setStreetId(e.value);
              setStreetName(e.label);
              setAddresses(null);
            }}
          />
        </>
      )}
      {addresses && (
        <>
          <Text mt="1rem" fontSize="sm">
            Wybierz numer domu
          </Text>
          <Select
            placeholder="Numer domu"
            options={formattedNumbers ?? []}
            onChange={(e: any) => {
              // Fix multiple street id for kleszczewo ex. 4567,5607
              const singleId = e.value.split(',')[0];
              setAddressId(singleId);
              setAddressLabel(e.label);
            }}
          />
        </>
      )}
    </>)
  );
};
